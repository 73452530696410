import React from "react";
import { Helmet } from "react-helmet";
import { Button, Link, Styled, Flex } from "theme-ui";
import Layout from "../../components/layout-main";
import { trackButtonCta } from "../../components/analytics";
import { getViewportWidth, scrollToElm } from "../../components/dom";
import { Carousel } from "react-responsive-carousel";
import { FirebaseContext } from "../../services";
import "firebase/firestore";

/** @jsx jsx */
import { jsx } from "theme-ui";

/**
 * Quick video React component to grab the (accurate) viewport width
 * after the component has mounted.
 */
export class VideoSplashComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      videoUrl: "",
    };
  }

  componentDidMount = () => {
    const viewportWidth = getViewportWidth();
    let videoFilename;
    let videoSplashUrl;
    if (viewportWidth > 1200) {
      // ["768px", "1025px", "1200px"]
      videoFilename = "afterword-splash-video";
    } else if (viewportWidth > 768) {
      videoFilename = "afterword-splash-video-small";
    } else {
      videoFilename = "afterword-splash-video-mobile";
    }
    videoSplashUrl =
      "https://storage.googleapis.com/afterword-static/recordings/Dr%20Andrew%20Hero%20Vid.mp4";

    this.setState({ videoUrl: videoSplashUrl });
  };
  render = () => {
    return (
      <video autoPlay loop muted playsInline tabIndex="-1">
        {this.state.videoUrl && (
          <source src={this.state.videoUrl} type="video/mp4" />
        )}
      </video>
    );
  };
}

const ThisPage = ({ location }) => {
  const fullName = `Andrew King Sing Cheng`;
  const zoomUrl = "";
  const storiesRecordUrl = "https://stories.afterword.com/fu26zs4oz";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/r04kneu18hz6sikrndbuxjky5movq20mv9m13rad";
  const imgStyles = { padding: ["1em 0", "3em 0"] };

  const { db, isInitialized } = React.useContext(FirebaseContext);
  const [carouselUrls, setCarouselUrls] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!isInitialized) {
      return;
    }
    db.collection("humans")
      .doc("o27vfTaje5M2sDRH3Mtp")
      // .doc("lIwuXATqucKKeeLPPjaX")
      .get()
      .then(function (result) {
        let data = result.data();
        setCarouselUrls(data.carouselUrls || []);
        setIsLoaded(true);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }, [isInitialized]);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/react-responsive-carousel@3.2.12/lib/styles/carousel.min.css"
        ></link>
      </Helmet>
      <Layout
        location={location}
        pageTitle={"Remembering " + fullName}
        hideLinks={true}
        hideSubscribe={true}
        buttonOverride={true && zoomUrl}
        noIndex={true}
      >
        <section className="aw-splash">
          <div className="aw-splash-grid">
            <div className="video-container">
              <div className="video-overlay"></div>
              <VideoSplashComponent />
            </div>
          </div>

          <Styled.h1
            sx={{
              color: "beige",
              marginBottom: ["10px", "45px", "60px"],
              pointerEvents: "none",
            }}
            className="aw-splash-header"
          >
            {fullName}
          </Styled.h1>

          <Styled.h3
            sx={{
              color: "beige",
              marginBottom: ["10px", "30px"],
              pointerEvents: "none",
            }}
            className="aw-splash-subtitle"
          >
            NOVEMBER 13, 1963 – APRIL 23, 2020
          </Styled.h3>
          <div className="aw-splash-button">
            <Link
              onClick={() => {
                trackButtonCta("watch-tribute-video");
                scrollToElm("tribute-video");
                setTimeout(() => {
                  document.querySelector("#tribute-video video").play();
                }, 0);
              }}
            >
              <Button
                variant="secondary"
                sx={{
                  borderColor: "black",
                }}
              >
                Watch Tribute
              </Button>
            </Link>
          </div>
        </section>

        <section sx={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
          <div className="aw-content" id="tribute-video">
            <h1
              sx={{
                fontWeight: "300",
                margin: 0,
                textAlign: "center",
                fontSize: [
                  "calc((2.8 - 1) * 0.4vw + 1em)",
                  "calc((2.8 - 1) * 1.2vw + 1em)",
                ],
              }}
            >
              Tribute Video
            </h1>
            <video
              controls
              allowFullScreen
              loop
              name="media"
              poster="https://storage.googleapis.com/afterword-static/recordings/Andrew%20Cheng.png"
              sx={{ width: "100%", "&:hover": { cursor: "pointer" } }}
            >
              <source
                src="https://storage.googleapis.com/afterword-static/recordings/dr%20andrew%20cheng%20revision%20april21-2.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </section>

        <section>
          <div className="aw-content">
            <h2>Andrew's Obituary</h2>
            <p>
              Dr. Andrew King Sing Cheng was born in Hong Kong on November 13,
              1963. He enrolled at La Salle primary school until he immigrated
              with his family to Vancouver, Canada in 1972. There he went to Sir
              William Osler and Eric Hamber Secondary where he met many of his
              lifelong best friends.
              <br />
              <br />
              After high school, Andrew enrolled at Queens University and
              University of Toronto graduating with a Bachelor of Arts and
              Science. He then studied Dentistry at University of Alberta where
              he graduated in 1989 setting in motion a 30 year career in
              dentistry and philanthropy. He opened several dental practices
              over the course of his life including ones in Burnaby, Squamish,
              Surrey, Vancouver and lastly in Tumbler Ridge, BC.
            </p>
            <p>
              <a
                href="https://www.dignitymemorial.com/obituaries/vernon-bc/andrew-cheng-9152832"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read the rest of his obituary here
              </a>
              .
            </p>

            {/* <h2>Honor Jimmy’s Legacy</h2>
            <p>
              To further pay tribute to his legacy please consider a gift to the{" "}
              <b>
                Jimmy Gamonet de los Heros’s Performing Arts Fund at the Miami
                Foundation.
              </b>{" "}
              This fund is dedicated to providing financial assistance to young
              talent with a passion for artistic expression, especially ballet.
            </p>
            <p>
              Checks can be made payable to The Miami Foundation c/o Jimmy
              Gamonet de los Heros’s Performing Arts Fund and mailed to The
              Miami Foundation, 40 NW Third Street, Suite 305, Miami, FL 33128.
              Or donate via credit card{" "}
              <a
                href="http://give.miamifoundation.org/jimmygamonet"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p> */}

            <h2>Share Your Photos & Videos</h2>
            <p>
              The family is collecting memories of Andrew—please help by adding
              your photos & videos using the button below.{" "}
            </p>
            <a
              href={"https://www.dropbox.com/request/1BwahIYibwqsvv1vDx5d"}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="outline" className="aw-btn-outline">
                Add Photos & Videos
              </Button>
            </a>
          </div>
        </section>

        {isLoaded && !!carouselUrls.length && (
          <section className="aw-accent">
            <div className="aw-content">
              <Carousel
                showArrows={true}
                infiniteLoop={true}
                showStatus={false}
                showThumbs={false}
                autoPlay={true}
                dynamicHeight={true}
                sx={{ marginTop: "24px", maxHeight: "600px" }}
              >
                {carouselUrls.map((img, idx) => {
                  return (
                    <div key={`carousel-img-${idx}`}>
                      <img src={img} alt={`Picture of ${fullName}`} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </section>
        )}

        <section>
          <div className="aw-content">
            <h2>Share a Story</h2>
            <p sx={{ display: ["block", "block", "none"] }}>
              To share a memory, click play followed by Video, Audio, or Text,
              depending on how you want to share
            </p>
            <iframe
              title="Share a story"
              src={storiesRecordUrl.replace(
                "//stories.afterword.com/",
                "//videoask.com/"
              )}
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              width="100%"
              height="600px"
              style={{ border: "none", borderRadius: "24px" }}
            ></iframe>
          </div>
        </section>

        <section>
          <div className="aw-content">
            <h2>Shared Stories</h2>
            <iframe
              title="memory-book"
              src={storiesSubmissionsUrl.replace(
                "//stories.afterword.com/",
                "//videoask.com/"
              )}
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              width="100%"
              height="600px"
              style={{ border: "none", borderRadius: "24px" }}
            ></iframe>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default ThisPage;
